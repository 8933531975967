import axios from "axios";

const state = {
  isLoggedIn: false,
  user: null,
  orders: [],
};

const mutations = {
  SET_LOGIN_STATUS(state, status) {
    state.isLoggedIn = status;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },
  CLEAR_USER(state) {
    state.user = null;
    state.orders = [];
  },
};

const actions = {
  async login({ commit }) {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      commit("SET_LOGIN_STATUS", true);
      try {
        const response = await axios.get(
          "https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/getCustomerDetails",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const { data } = response.data;

        if (
          response.data?.errorDetails?.errorMessage ==
          "Missing or invalid token"
        ) {
          commit("SET_LOGIN_STATUS", false);
          commit("CLEAR_USER");
          this.modalTitle = "Please login.";
          this.modalMessage =
            "Your session is expired, login again to continue.";
          this.modalStatus = "failure";
          this.isModalVisible = "true";

          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        } else {
          commit("SET_USER", data.customer);
          commit("SET_ORDERS", data.orders);
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    }
  },

  async initializeAuth({ commit }) {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      commit("SET_LOGIN_STATUS", true);
      try {
        const response = await axios.get(
          "https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/getCustomerDetails",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const { data } = response.data;

        if (
          response.data?.errorDetails?.errorMessage ==
          "Missing or invalid token"
        ) {
          commit("SET_LOGIN_STATUS", false);
          commit("CLEAR_USER");
          this.modalTitle = "Please login.";
          this.modalMessage =
            "Your session is expired, login again to continue.";
          this.modalStatus = "failure";
          this.isModalVisible = "true";

          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        } else {
          commit("SET_USER", data.customer);
          commit("SET_ORDERS", data.orders);
        }
      } catch (error) {
        console.error("Failed to initialize user details:", error);
      }
    }
  },

  logout({ commit }) {
    localStorage.removeItem("authToken");
    commit("SET_LOGIN_STATUS", false);
    commit("CLEAR_USER");
  },
};

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  user: (state) => state.user,
  orders: (state) => state.orders,
  userAddress: (state) => state.user?.address,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
