<template>
    <NavBar />
    <div class="container">
        <header class="header">
            <h1>Choose Your Delivery Option</h1>
        </header>

        <div class="options">
            <button :class="{ active: selectedOption === 'general' }" @click="selectedOption = 'general'">
                General
            </button>
            <button :class="{ active: selectedOption === 'express' }" @click="selectedOption = 'express'"
                @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                Express
                <span v-if="showTooltip" class="tooltip">
                    +$1 per item
                </span>
            </button>
        </div>

        <div class="container-service">
            <div class="service-section">
                <h3>General</h3>
                <h6>Clean, Convenient, Carefree</h6>
                <p>No rush? No problem! With our General Pickup, we provide reliable, top-notch cleaning delivered
                    right to your doorstep in just <span class="highlight">4 days</span>. Perfect for your routine
                    laundry needs—let us
                    take care of it while you enjoy the convenience!
                </p>
            </div>

            <div class="user-details">
                <h3>Receiver Details</h3>
                <div class="form-group">
                    <label for="first-name">First Name:</label>
                    <input id="first-name" type="text" v-model="userDetails.firstName"
                        placeholder="Enter your first name" />
                </div>

                <div class="form-group">
                    <label for="last-name">Last Name:</label>
                    <input id="last-name" type="text" v-model="userDetails.lastName"
                        placeholder="Enter your last name" />
                </div>

                <div class="form-group">
                    <label for="address">Address:</label>
                    <input id="address" type="text" placeholder="Enter your address" :value="fullAddress" readonly />
                </div>

                <div class="form-group">
                    <label for="phone">Phone Number:</label>
                    <input id="phone" type="tel" v-model="userDetails.phoneNumber"
                        placeholder="Enter your phone number" @keypress="onlyNumber" maxlength="10">
                </div>
            </div>

            <div class="service-section">
                <h3>Express</h3>
                <h6>Fast, Fresh, and On-Time!</h6>
                <p>Need your clothes in a hurry? Our Express Pickup gets your laundry back in just <span
                        class="highlight">2 days</span>.
                    For only <span class="highlight">$1</span> extra per item, enjoy priority service that fits your
                    schedule without compromising on quality. When time matters, we’ve got you covered!
                </p>
            </div>
        </div>


        <div>
            <button class="order-btn" @click="placeOrder">Place your order</button>
        </div>

        <Swiper :modules="modules" space-between="10" :slides-per-view="5" :autoplay="{
            delay: 3000,
            disableOnInteraction: false
        }" :effect="'fade'" class="image-swiper">
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/jeans.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Pants</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/skirt.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Skirts</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/blouse.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Blouse</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/sweater.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Sweater</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/L-Shirt.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>L-Shirt(L/S)</h3>
                    <p>$2.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/overcoat.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Over Coat</h3>
                    <p>$14.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/coat.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>3/4 Coat</h3>
                    <p>$9.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/dress.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Dress</h3>
                    <p>$8.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/short.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Short</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/t-shirt.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>T-Shirt</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/dress shirt.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Dress Shirt</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/vest.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Vest</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/2PC Suit.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>2PC Suit</h3>
                    <p>$9.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/3PC Suit.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>3PC Suit</h3>
                    <p>$15.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/jacket.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Regular Jacket</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/sports jacket.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Sport Jacket</h3>
                    <p>$8.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/tie.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Tie</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/jeans.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Jeans</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/default.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Tuxedo</h3>
                    <p>$8.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/scarf.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Scarf</h3>
                    <p>$4.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/jumpsuit.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Jump Suit</h3>
                    <p>$9.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/default.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Comfort Queen</h3>
                    <p>$29.99</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/bed.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>BedSpread Twin</h3>
                    <p>$20.00</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/default.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>TableCloth Mid</h3>
                    <p>$15.00</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/towel.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Blanket Mid</h3>
                    <p>$28.00</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/window.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Valance</h3>
                    <p>$15.00</p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div class="pricing-item">
                    <img src="../assets/icons/textile.png" :alt="'Slide' + (index + 1)" class="swiper-image" />
                    <h3>Pillow Case</h3>
                    <p>$3.99</p>
                </div>
            </SwiperSlide>
        </Swiper>

        <div class="content">
            <div class="features">
                <h4>Things to keep in mind</h4>
                <ul>
                    <li>Garments shall be sent back folded, not on hangers.</li>
                    <li>This service is available for pick-up and delivery both.</li>
                    <li>This service is currently not applicable on carpets.</li>
                    <li>Available in Powell, Columbus, and Hilliard.</li>
                </ul>
                <p>Just sit back and relax while we pick up, service, and deliver your possessions back to you looking
                    as good as new.</p>
            </div>
        </div>
    </div>
    <Footer />
    <MessageModal :isVisible="isModalVisible" :status="modalStatus" :title="modalTitle" :message="modalMessage"
        @close="handleModalClose" @go="redirectTo" />
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules'
import 'swiper/swiper-bundle.css';
import NavBar from './NavBar.vue';
import Footer from './Footer.vue';
import MessageModal from './messageModal.vue';

export default {
    components: {
        Swiper,
        SwiperSlide,
        NavBar,
        Footer,
        MessageModal
    },
    data() {
        return {
            selectedOption: 'general',
            items: [],
            modules: [Autoplay],
            pickupDate: '',
            pickuptime: '',
            isModalVisible: false,
            modalTitle: '',
            modalStatus: '',
            modalMessage: '',
            userDetails: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
            },
            showTooltip: false,
        };
    },
    computed: {
        addressData() {
            return this.$store.getters.getAddress;
        },
        fullAddress() {
            const { address, secondary, city, state, zipcode } = this.addressData;
            return `${address}${secondary ? ' ' + secondary : ''}, ${city}, ${state} ${zipcode}`;
        },
    },
    created() {
        this.pickupDate = this.$route.query.date;
        this.populateUserDetails();
        // console.log('Address data on created:', this.addressData);
    },
    methods: {
        onlyNumber(event) {
            const char = String.fromCharCode(event.which);
            if (!/[0-9]/.test(char)) {
                event.preventDefault();
            }
        },
        populateUserDetails() {
            const user = this.$store.getters.user;
            if (user) {
                const names = user.customeName.split(" ");
                const firstName = names[0];
                const lastName = names.slice(1).join(" ");
                this.userDetails.firstName = firstName || '';
                this.userDetails.lastName = lastName || '';
                this.userDetails.phoneNumber = user.phoneNumber || '';
            }
        },
        getCurrentDateTime() {
            const now = new Date();
            return now.toISOString().slice(0, 19); //'YYYY-MM-DDTHH:mm:ss'
        },

        async placeOrder() {
            const orderType = this.selectedOption.toUpperCase();

            const orderPayload = {
                order: {
                    firstName: this.userDetails.firstName,
                    lastName: this.userDetails.lastName,
                    address: this.fullAddress,
                    phoneNumber: this.userDetails.phoneNumber,
                    orderType: orderType,
                    pickupDate: this.pickupDate,
                    remarks: "handle with care"
                },
                items: this.items,
            };

            const token = localStorage.getItem('authToken')

            if (!token) {
                this.modalTitle = 'Failure'
                this.modalMessage = "Please login to place the order."
                this.modalStatus = "warning"
                this.isModalVisible = true
                setTimeout(() => {
                    this.$router.push('/login')
                }, 2000);
            }

            try {
                const response = await fetch('https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/createOrder', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(orderPayload),
                });

                if (!response.ok) {
                    throw new Error('Failed to place order');
                }

                const data = await response.json();
                if (data.status === "success") {
                    // const orderDetails = data.data.order;

                    this.showModal(
                        'Order Successful',
                        `Keep an eye out for our pickup truck.
                        We look forward to collecting your clothes!
                        `,
                        'success'
                    );
                    localStorage.removeItem('addressData');
                    this.$store.dispatch('updateAddress', {
                        address: '',
                        secondary: '',
                        city: '',
                        state: 'OH',
                        zipcode: '',
                    });
                } else {
                    this.showModal('failure', 'Failed to create order. Please try again.', 'failure');
                }
            } catch (error) {
                this.showModal('failure', 'Failed to place order. Please try again.', 'failure');
            }
        },
        showModal(title, message, status) {
            this.modalTitle = title;
            this.modalMessage = message;
            this.modalStatus = status;
            this.isModalVisible = true;

            // this.$router.push('/')
        },
        handleModalClose(shouldRedirect) {
            this.isModalVisible = false;
            if (shouldRedirect) {
                this.$router.push('/');
            }
        },
        redirectTo(goTo) {
            this.isModalVisible = false;
            if (goTo) {
                this.$router.push('/order-history')
            }
        }
    },
};
</script>

<style scoped>
.container-service {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    flex-wrap: wrap;
}

.service-section {
    flex: 1;
    width: 50%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.service-section h3 {
    font-size: 22px;
    color: #0a57a2;
    margin-bottom: 10px;
}

.service-section h6 {
    font-size: 16px;
    color: #4184c7;
    margin-bottom: 10px;
}

.service-section p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

.highlight {
    color: #0a57a2;
    font-weight: bold;
}

.user-details {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.user-details h3 {
    font-size: 22px;
    margin-bottom: 15px;
    text-align: center;
    color: #333;
}

.user-details .form-group {
    margin-bottom: 15px;
}

.user-details label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.user-details input {
    width: 95%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.user-details input:focus {
    border-color: #0a57a2;
    outline: none;
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .container-service {
        flex-direction: column;
        align-items: center;
        width: fit-content;
    }

    .service-section {
        flex: 0 0 100%;
        width: 80%;
        margin-bottom: 20px;
    }

    .user-details {
        width: 80%;
    }
}

.navbar {
    position: sticky;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-header {
    padding: 20px;
    width: 100%;
    text-align: center;
    /* background-color: #f8f9fa; */
}

.content {
    margin-top: 20px;
    text-align: center;
    font-size: 1.2rem;
}

.features {
    margin-top: 10px;
    text-align: left;
    font-style: italic
}

.features h4 {
    color: rgb(59, 59, 59);
}

.features ul {
    font-size: 1rem;
    color: gray;
    list-style-type: disc;
    padding-left: 20px;
}

.features p {
    margin-top: 10px;
    font-size: medium;
    font-weight: 600;
    color: rgb(59, 59, 59);
}

.options {
    margin: 20px 0;
    display: flex;
    gap: 10px;
}

.options button {
    position: relative;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.options button.active {
    background-color: #0a57a2;
    color: white;
}

.options button:hover {
    background-color: #0a57a2;
    color: white;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 8px;
    /* Space between the tooltip and the button */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.options button:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.order-btn {
    background-color: #28a745;
    color: white;
    padding: 15px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.order-btn:hover {
    background-color: #218838;
}

/* Swiper styling */
.image-swiper {
    width: 80%;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
}

.pricing-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    transition: transform 0.3s ease-in-out;
}

.pricing-item:hover {
    transform: translateY(-5px);
}

.swiper-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 10px;
}

.pricing-item h3 {
    font-size: 1rem;
    color: #333;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pricing-item p {
    font-size: 1rem;
    color: #0a57a2;
    font-weight: bold;
    margin: 5px 0 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .header {
        text-align: center;
    }

    .swiper-image {
        max-width: 130%
    }

    .image-swiper {
        width: 100%;
    }

    .pricing-item {
        padding: 15px;
    }

    .pricing-item h3,
    .pricing-item p {
        font-size: small;
    }

    .content {
        padding: 20px;
    }
}
</style>