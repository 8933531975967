<template>
    <div id="app">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series">
        </apexchart>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
    components: {
        apexchart: VueApexCharts,
    },
    setup() {
        const colors = [
            '#00E396',
            '#FF0000',
            '#FFA500',
            '#ccc',
        ];

        const series = ref([{
            data: [],
        }]);

        const chartOptions = ref({
            chart: {
                height: 350,
                type: 'bar',
            },
            colors: colors,
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: ['Delivered', 'Cancelled', 'In_progress', 'Created'],
                labels: {
                    style: {
                        colors: colors,
                        fontSize: '12px'
                    }
                }
            }
        });

        // Function to fetch order data
        const fetchOrderData = async () => {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch("https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/fetchOrders?size=200", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                const data = await response.json(); // Parse the response as JSON
                const orders = data.data.orders;

                // Process the response data to extract counts for each order status
                const orderCounts = orders.reduce((acc, order) => {
                    acc[order.orderStatus] = (acc[order.orderStatus] || 0) + 1;
                    return acc;
                }, {});

                // Populate the series and categories for the chart
                series.value[0].data = Object.values(orderCounts);
                chartOptions.value.xaxis.categories = Object.keys(orderCounts);
                // console.log(chartOptions.value.xaxis.categories);

            } catch (error) {
                console.error('Error fetching order data:', error);
            }
        };

        // Fetch order data on component mount
        onMounted(() => {
            fetchOrderData();
        });

        return {
            series,
            chartOptions,
        };
    },
});
</script>

<style></style>