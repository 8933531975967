<template>
  <NavBar />
  <div class="order-history">
    <div class="links-container">
      <router-link to="/schedule" class="back-link"><i class="bi bi-arrow-left"></i> Back to Schedule </router-link>
      <button @click="handleLogout" class="logout-button">Logout</button>
    </div>

    <div id="container" class="dashboard">
      <div class="dashboard-left">
        <!-- Customer Details Section -->
        <div class="customer-details" v-if="customer">
          <h2>Your Details</h2>
          <div class="detail-item">
            <span class="label">Name</span>
            <span class="value">{{ customer.customeName }}</span>
          </div>
          <div class="detail-item">
            <span class="label">Address</span>
            <span class="value">{{ customer.address }}</span>
          </div>
          <div class="detail-item">
            <span class="label">City</span>
            <span class="value">{{ customer.city }}</span>
          </div>
          <div class="detail-item">
            <span class="label">Number</span>
            <span class="value">{{ customer.phoneNumber }}</span>
          </div>
        </div>
        <div class="welcome-content">
          <h2>Welcome to Max Cleaners!</h2>
          <p>Thank you for choosing us for your laundry needs. We are committed to providing top-notch services that
            are
            quick, reliable, and tailored just for you.</p>
          <h3>Quick Tips:</h3>
          <ul>
            <li>Always check for items in pockets before sending clothes for cleaning.</li>
            <li>Schedule your pickups in advance for better availability.</li>
            <li>We recommend our express service for urgent needs!</li>
          </ul>
          <p>Need assistance? <strong><a href="tel:+1 (614) 218-1183">Contact Us</a></strong> for support!</p>
        </div>

      </div>

      <div class="dashboard-right">
        <h2>Order History</h2>
        <!-- No Orders Section -->
        <div v-if="orders.length === 0" class="no-orders">
          <p>It looks like you don’t have any orders yet.</p>
          <p>
            Why not give <strong><a href="/">Max Cleaners</a></strong> a try? We provide top-notch laundry services
            that
            are quick, reliable, and tailored to your needs.
          </p>
          <p>
            <router-link to="/schedule" class="schedule-link">Schedule Your Pickup</router-link>
          </p>
          <div class="services">
            <h2>Our Services</h2>
            <ul>
              <li>Laundry</li>
              <li>Pickup</li>
              <li>Delivery</li>
              <li>Dry Cleaning</li>
              <li>Tailoring</li>
            </ul>
          </div>
        </div>

        <!-- Orders Section -->
        <div v-else>
          <div class="order-filters">
            <p id="span-bold">Search by order status</p>
            <select v-model="selectedStatus" @change="filterOrders">
              <option value="">All</option>
              <option value="CREATED">CREATED</option>
              <option value="IN_PROGRESS">IN_PROGRESS</option>
              <option value="DELIVERED">DELIVERED</option>
              <option value="CANCELLED">CANCELLED</option>
            </select>
          </div>
          <ul class="order-list">
            <li v-for="order in filteredOrders" :key="order.id" class="order-item">
              <div class="order-summary">
                <p :class="['status', order.orderStatus.toLowerCase()]" id="status">{{ order.orderStatus }}</p>
                <span><span id="span-bold">Order ID:</span> {{ order.id }}</span>
                <p class="order-date"><span id="span-bold">Order Date:</span> {{ formatDate(order.createdAt, pickup, false) }}</p>
                <p class="order-type"><span id="span-bold">Service:</span> {{ order.orderType }}</p>
                <p><span id="span-bold">Delivery Date:</span> {{ formatDate(order.deliveryDate) }}</p>
                <p class="address"><span id="span-bold">Pickup Status:</span> {{ order.pickupStatus }}</p>
                <p><span id="span-bold">Pickup Date:</span> {{ formatDate(order.pickupDate, pickup, true) }}</p>
                <div class="receiver-details">
                  <p id="span-bold" class="heading">Receiver Details</p>
                  <p><span id="span-bold">Name:</span> {{ order.customerName }}</p>
                  <p><span id="span-bold">Number: </span>{{ order.phoneNumber }}</p>
                  <p><span id="span-bold">Address: </span>{{ order.address }}</p>
                </div>
              </div>
              <div class="order-details">
                <div class="order-info">
                  <p><span id="span-bold">Get Your Bill Here</span></p>
                </div>
                <div class="order-actions">
                  <button class="view-receipt-button" @click="viewReceipt(order.id)">View Receipt</button>
                </div>

              </div>

            </li>
          </ul>

          <!-- Pagination Controls -->
          <div class="pagination-controls">
            <button @click="previousPage" :disabled="currentPage === 0">Previous</button>
            <button @click="nextPage" :disabled="(currentPage + 1) * pageSize >= totalOrders">Next</button>
          </div>
        </div>
      </div>
    </div>
    <!-- New Image Modal -->
    <div v-if="isImageModalVisible" class="image-modal-overlay">
      <div class="image-modal">
        <button class="close-button" @click=handleImageModal>&times;</button>
        <img :src="imageUrl" alt="Receipt Image" class="receipt-image" />
        <button class="download-receipt-button" v-if="showDownloadButton" @click="downloadReceipt">Download
          Receipt</button>
      </div>
    </div>
  </div>
  <FooTer />
  <MessageModal :isVisible="isModalVisible" :status="modalStatus" :title="modalTitle" :message="modalMessage"
    @close="isModalVisible = false" @go="redirectTo" />
</template>

<script>
import FooTer from './Footer.vue';
import NavBar from './NavBar.vue';
import { mapActions } from 'vuex';
import axios from 'axios';
import MessageModal from './messageModal.vue';

export default {
  data() {
    return {
      orders: [],
      customer: null,
      searchQuery: '',
      selectedStatus: '',
      filteredOrders: [],
      isModalVisible: false,
      modalTitle: '',
      modalStatus: '',
      modalMessage: '',
      currentPage: 0, // Track the current page
      pageSize: 3,    // Number of orders per page
      totalOrders: 0,
      isImageModalVisible: false,
      imageUrl: '',
      imageBlob: null,
      download_id: '',
      receiverDetails: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        address: ''
      }
    };
  },
  components: {
    NavBar,
    FooTer,
    MessageModal
  },
  created() {
    this.fetchOrderHistory(this.currentPage);
  },
  methods: {
    async fetchOrderHistory(page) {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        this.$router.push('/login');
        return;
      }

      try {
        const response = await axios.get(`https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/getCustomerDetails?page=${page}&size=${this.pageSize}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.data?.errorDetails?.errorMessage == 'Missing or invalid token') {
          this.modalTitle = "Please login."
          this.modalMessage = "Your session is expired, login again to continue."
          this.modalStatus = "failure"
          this.isModalVisible = 'true'
          setTimeout(() => {
            this.$router.push('/login')
          }, 2000);
        } else {
          const { data } = response.data;
          this.orders = data.orders;
          this.totalOrders = data.total;
          this.customer = data.customer;
          this.filteredOrders = this.orders;
          // console.log(this.filteredOrders);

        }
      } catch (error) {
        console.error('Failed to fetch order history:', error);
      }
    },
    formatDate(date, type, showPreposition = true) {
      const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
      const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true }; // Change hour12 to false for 24-hour format

      const formattedDate = new Date(date).toLocaleDateString(undefined, optionsDate);
      const formattedTime = new Date(date).toLocaleTimeString(undefined, optionsTime);

      if (showPreposition) {
        const preposition = type === 'pickup' ? 'at' : 'by';
        return `${formattedDate} ${preposition} ${formattedTime}`;
      } else {
        return `${formattedDate} ${formattedTime}`;
      }
    },
    // calculateTotal(items) {
    //   return items.reduce((total, item) => total + item.price * item.quantity, 0);
    // },
    filterOrders() {
      this.filteredOrders = this.orders.filter(order => {
        return (
          (this.selectedStatus === '' || order.orderStatus === this.selectedStatus));
      });
    },
    nextPage() {
      if ((this.currentPage + 1) * this.pageSize < this.totalOrders) {
        this.currentPage++;
        this.fetchOrderHistory(this.currentPage);
      }
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.fetchOrderHistory(this.currentPage);
      }
    },
    async viewReceipt(orderId) {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(
          `https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/orders/${orderId}/viewImage`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const base64Data = response.data.data;

        // Convert Base64 to Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Create a Blob from the byte array
        const blob = new Blob([byteNumbers], { type: 'image/jpeg' });

        // Create a URL from the Blob and store it
        this.imageBlob = blob;
        this.imageUrl = URL.createObjectURL(blob);
        this.download_id = orderId

        // Show the modal with the image
        this.isImageModalVisible = true;
        this.showDownloadButton = true;
      } catch (error) {
        this.modalTitle = 'Oops!!'
        this.modalMessage = error.response.data.message
        this.modalStatus = 'failure'
        this.isModalVisible = true;
        this.$emit('close');

      }
    },
    downloadReceipt() {
      if (this.imageUrl) {
        const link = document.createElement('a');
        link.href = this.imageUrl;
        link.download = `receipt_${this.download_id}.jpeg`;
        link.click();
        URL.revokeObjectURL(link.href);
        this.download_id = '';
      }
    },
    handleImageModal() {
      this.imageUrl = ''
      this.isImageModalVisible = false;
    },
    redirectTo(goTo) {
      if (goTo) {
        this.isModalVisible = false;
      }
    },
    handleLogout() {
      this.logout().then(() => {
        localStorage.removeItem('authToken');
        this.$router.push('/login');
      });
    },
    ...mapActions(['logout']),
  },
};
</script>
<style scoped>
.container {
  border: 1px solid red;
}

.links-container {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}

#container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard {
  display: flex;
  justify-content: space-between;
  margin: auto;
  gap: 20px;
}

.dashboard-left,
.dashboard-right {
  background-color: hsl(198.02deg 15.5% 87.6% /82%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: fit-content;
  height: fit-content;
}

.dashboard-left {
  flex: 1;
}

.dashboard-right {
  flex: 2;
}

.welcome-content {
  padding: 20px;
  font-style: italic;
  border-radius: 10px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination-controls button {
  margin: 0 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-controls button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #0056b3;
}

.order-list {
  list-style: none;
  padding: 0;
}

.back-link {
  display: inline-block;
  margin-bottom: 20px;
  color: #0056b3;
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}

.customer-details {
  border-radius: 10px;
  max-width: 600px;
}

.customer-details .value {
  font-weight: 500;
  font-style: italic;
  text-align: left;
}

.customer-details h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.detail-item:last-child {
  border-bottom: none;
}

.label {
  text-align: center;
  background-color: #0056b3;
  padding: 2px;
  flex: 0 0 20%;
  border-radius: 4px;
  font-weight: bold;
  color: rgb(241, 241, 241);
}

.value {
  flex: 1;
  text-align: right;
  color: black;
}

.order-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  background: url('https://images.pexels.com/photos/10565603/pexels-photo-10565603.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2') no-repeat center center;
  background-size: cover;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.no-orders {
  text-align: center;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.no-orders p {
  margin: 10px 0;
  color: #555;
}

.no-orders strong a {
  text-decoration: none;
  color: #0a57a2;
}

.schedule-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #0a57a2;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.schedule-link:hover {
  background-color: #0056b3;
}

.services {
  margin-top: 20px;
}

.services h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.services ul {
  list-style-type: none;
  padding: 0;
}

.services li {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.order-filters {
  display: flex;
  gap: 5px;
  width: max-content;
  margin-bottom: 20px;
}

.order-filters select {
  background-color: transparent;
  width: 30%;
  font-size: 16px;
  border-radius: 5px;
}

.order-filters select:focus {
  border-color: #0a57a2;
}

.order-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.order-item {
  background: #595a5b54;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  position: relative;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: space-between;
}

.order-item:hover {
  transform: translateY(-3px);
}

.order-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 10px;
}

.order-summary p {
  margin: 5px;
}

#status {
  font-weight: bold;
  font-size: 16px;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 25px;
}

.created {
  background-color: #3498db;
}

.in_progress {
  background-color: #f39c12;
}

.delivered {
  background-color: #27ae60;
}

.cancelled {
  background-color: #e74c3c;
}

#span-bold {
  font-weight: bold;
}

.order-date {
  font-size: 14px;
}

.order-items {
  font-size: 14px;
}

.order-items span {
  display: block;
}

.order-total {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.receiver-details {
  margin-top: 10px;
}

.receiver-details .heading {
  color: #0a57a2;
}


.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 80%;
  overflow: auto;
  position: relative;
  text-align: center;
}

.receipt-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.download-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.view-receipt-button,
.download-receipt-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-receipt-button:hover,
.download-receipt-button:hover {
  background-color: #0056b3;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .navbar {
    position: fixed;
    background-color: #bec4c5;
  }

  .links-container {
    margin-top: 25%;
  }

  .dashboard {
    flex-direction: column;
    gap: 15px;
  }

  #container {
    padding: 5px;
    margin-right: 5%
  }

  .dashboard-left,
  .dashboard-right {
    width: 100%;
  }

  .order-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-summary {
    margin-bottom: 15px;
  }

  .order-filters {
    flex-direction: column;
    width: 100%;
  }

  .order-filters select {
    width: 100%;
    margin-bottom: 10px;
  }

  .pagination-controls {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {

  .dashboard-left,
  .dashboard-right {
    padding: 10px;
  }

  h1 {
    font-size: 22px;
  }

  .logout-button,
  .download-button,
  .schedule-link {
    padding: 8px 15px;
    font-size: 14px;
  }
}
</style>
