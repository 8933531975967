<template>
    <div class="page-container">
        <router-link to="/">
            <div id="heading">
                <h1>Max Cleaners</h1>
                <h6>Free pickup & Quickly Delivered.</h6>
            </div>
        </router-link>
        <div class="login-wrapper">
            <div class="form-container">
                <transition name="fade" mode="out-in">
                    <div :key="isLogin ? 'login' : 'signup'" class="form-wrapper">
                        <div class="heading">
                            <h3 v-if="isLogin">Sign in to your account.</h3>
                            <h3 v-else>Create a new account.</h3>
                        </div>

                        <form v-if="isLogin" @submit.prevent="handleSubmit">
                            <p class="auth-switch-text">New user? <a class="auth-method-text" href="#"
                                    @click.prevent="toggleForm">Create an
                                    account</a></p>
                            <div class="input-group">
                                <label :class="{ active: phoneNumber }" for="phoneNumber">Phone Number</label>
                                <span class="phone-prefix">+1</span>
                                <input type="tel" id="phoneNumber" class="input-field phone" placeholder="Phone Number"
                                    v-model="phoneNumber" @keypress="onlyNumber" @input="validatePhoneNumber"
                                    maxlength="10" />
                            </div>
                            <div class="input-group">
                                <label :class="{ active: password }" for="password">Password</label>
                                <input id="password" class="input-field" placeholder="Password"
                                    :type="showPassword ? 'text' : 'password'" v-model="password"
                                    @input="validatePassword" />
                                <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"
                                    @click="togglePasswordVisibility" class="password-toggle-icon"></i>
                            </div>
                            <div v-if="loginError" class="error-message">{{ loginError }}</div>
                            <div class="input-group">
                                <button type="submit">Login</button>
                            </div>
                        </form>

                        <form v-else @submit.prevent="handleSignup">
                            <p class="auth-switch-text">Have an account? <a class="auth-method-text" href="#"
                                    @click.prevent="toggleForm">Sign in
                                    here!</a></p>
                            <div class="input-group">
                                <label :class="{ active: firstName }" for="firstName">First Name</label>
                                <input type="text" id="firstName" class="input-field" placeholder="First Name"
                                    v-model="firstName" @input="validateFirstName" />
                            </div>
                            <div class="input-group">
                                <label :class="{ active: lastName }" for="lastName">Last Name</label>
                                <input type="text" id="lastName" class="input-field" placeholder="Last Name"
                                    v-model="lastName" @input="validateLastName" />
                            </div>
                            <div class="input-group">
                                <label :class="{ active: signupPhoneNumber }" for="signupPhoneNumber">Phone
                                    Number</label>
                                <span class="phone-prefix">+1</span>
                                <input type="tel" id="signupPhoneNumber" class="input-field phone"
                                    placeholder="Phone Number" v-model="signupPhoneNumber" @keypress="onlyNumber"
                                    @input="validateSignupNumber" maxlength="10" />
                            </div>
                            <div id="input-group">
                                <div class="input-group input-container">
                                    <label :class="{ active: address }" placeholder="address">Address</label>
                                    <input type="text" id="address" class="input-field" placeholder="Address"
                                        v-model="address" @input="validateAddress" />
                                </div>
                                <div class="input-group input-container">
                                    <label :class="{ active: secondary }" placeholder="address">Secondary</label>
                                    <input type="text" id="secondary-address" class="input-field"
                                        placeholder="unit or apartment or suite number" v-model="secondary" />
                                </div>
                                <div class="input-group input-container">
                                    <label :class="{ active: city }" placeholder="City">City</label>
                                    <select id="city" class="input-field" v-model="city" @change="updateZipcode">
                                        <option value="" disabled>Select a city</option>
                                        <option v-for="(zipcodes, cityName) in cityZipcodeMap" :key="cityName"
                                            :value="cityName">
                                            {{ cityName }}
                                        </option>
                                    </select>
                                </div>
                                <div class="input-group input-container">
                                    <label :class="{ active: state }" placeholder="state">State</label>
                                    <input type="text" id="state" class="input-field" placeholder="State" value="OH"
                                        v-model="state" @input="validateState" readonly />
                                </div>
                                <div v-if="zipCodes.length > 0 && city !== 'Columbus'"
                                    class="input-group input-container">
                                    <label :class="{ active: zipcode }" placeholder="zipcode">Zipcode</label>
                                    <select id="zipcode" class="input-field" v-model="zipcode" required>
                                        <option value="" disabled>Select Zip Code</option>
                                        <option v-for="zip in zipCodes" :key="zip" :value="zip">{{ zip }}</option>
                                    </select>
                                </div>
                                <!-- Show zipcode input field for Columbus -->
                                <div v-if="city === 'Columbus'" class="input-group input-container">
                                    <label :class="{ active: zipcode }" placeholder="zipcode">Zipcode</label>
                                    <input type="text" id="zipcode" class="input-field" v-model="zipcode"
                                        placeholder="Enter Zip Code" @keypress="onlyNumber" />
                                </div>
                            </div>
                            <div class="input-group">
                                <label :class="{ active: signupPassword }" for="signupPassword">Password</label>
                                <input id="signupPassword" class="input-field" placeholder="Password"
                                    :type="showSignupPassword ? 'text' : 'password'" v-model="signupPassword"
                                    @input="validateSignupPassword" />
                                <i :class="showSignupPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"
                                    @click="toggleSignupPasswordVisibility" class="password-toggle-icon"></i>
                            </div>
                            <div class="input-group">
                                <label :class="{ active: signupConfirmPassword }" for="signupConfirmPassword">Confirm
                                    Password</label>
                                <input id="signupConfirmPassword" class="input-field" placeholder="Confirm Password"
                                    :type="showSignupConfirmPassword ? 'text' : 'password'"
                                    v-model="signupConfirmPassword" @input="validateSignupConfirmPassword" />
                                <i :class="showSignupConfirmPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"
                                    @click="toggleSignupConfirmPasswordVisibility" class="password-toggle-icon"></i>
                            </div>
                            <div v-if="signupError" class="error-message">{{ signupError }}</div>
                            <div class="input-group">
                                <button type="submit">Signup</button>
                            </div>
                        </form>
                    </div>
                </transition>
            </div>
        </div>
    </div>
    <MessageModal :isVisible="isModalVisible" :title="modalTitle" :status="modalStatus" :message="modalMessage"
        @close="isModalVisible = false" @go="redirectTo" />
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import MessageModal from './messageModal.vue';

export default {
    name: "LoginForm",
    components: {
        MessageModal
    },
    data() {
        return {
            phoneNumber: "",
            password: "",
            showPassword: false,
            loginError: "",
            firstName: "",
            lastName: "",
            signupPhoneNumber: "",
            address: '',
            secondary: '',
            city: '',
            state: 'OH',
            zipcode: '',
            cityZipcodeMap: {
                Westerville: ['43081', '43082', '43086'],
                Worthington: ['43085', '43229'],
                Powell: ['43065'],
                Dublin: ['43002', '43016', '43017'],
                Delaware: ['43015'],
                'Lewis Center': ['43015', '43035'],
                Columbus: ''
            },
            zipCodes: [],
            signupPassword: "",
            signupConfirmPassword: "",
            showSignupPassword: false,
            showSignupConfirmPassword: false,
            signupError: "",
            isLogin: true,
            isModalVisible: false,
            modalTitle: '',
            modalStatus: '',
            modalMessage: '',
        };
    },
    methods: {
        redirectTo() {
            this.isModalVisible = false
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        toggleSignupPasswordVisibility() {
            this.showSignupPassword = !this.showSignupPassword;
        },
        toggleSignupConfirmPasswordVisibility() {
            this.showSignupConfirmPassword = !this.showSignupConfirmPassword;
        },
        onlyNumber(event) {
            const char = String.fromCharCode(event.which);
            if (!/[0-9]/.test(char)) {
                event.preventDefault();
            }
        },
        validatePhoneNumber() {
            this.loginError = "";
            if (!this.phoneNumber) {
                this.loginError = "Phone number is required.";
            } else if (this.phoneNumber.length !== 10) {
                this.loginError = "Phone number must be 10 digits.";
            }
        },
        validatePassword() {
            this.loginError = "";
            if (!this.password) {
                this.loginError = "Password is required.";
            } else if (this.password.length < 6) {
                this.loginError = "Password must be at least 6 characters long.";
            }
        },
        validateFirstName() {
            this.signupError = "";
            if (!this.firstName) {
                this.signupError = "First Name is required.";
            }
        },
        validateLastName() {
            this.signupError = "";
            if (!this.lastName) {
                this.signupError = "Last Name is required.";
            }
        },
        validateSignupNumber() {
            this.signupError = "";
            if (!this.signupPhoneNumber) {
                this.signupError = "Phone number is required.";
            } else if (this.signupPhoneNumber.length !== 10) {
                this.signupError = "Phone number must be 10 digits.";
            }
        },
        validateAddress() {
            this.signupError = "";
            if (!this.address) {
                this.signupError = "Address is required.";
            }
        },
        validateCity() {
            this.signupError = "";
            if (!this.city) {
                this.signupError = "City is required.";
            }
        },
        validateState() {
            this.signupError = "";
            if (!this.state) {
                this.signupError = "State is required.";
            }
        },
        validateZipcode() {
            this.signupError = "";
            if (!this.zipcode) {
                this.signupError = "Zipcode is required.";
            }
        },
        updateZipcode() {
            const selectedCity = this.city;

            // Handle Columbus separately, allowing manual input for the zip code
            if (selectedCity === 'Columbus') {
                this.zipCodes = []; // No zip codes to select from
                this.zipcode = ''; // Clear the existing zip code input
            } else {
                // Fetch zip codes for other cities from the cityZipcodeMap
                this.zipCodes = this.cityZipcodeMap[selectedCity] || [];

                // Auto-fill zip code if there's only one option for the selected city
                if (this.zipCodes.length === 1) {
                    this.zipcode = this.zipCodes[0];
                } else {
                    this.zipcode = ''; // Reset the zip code input if there are multiple options
                }
            }
        },
        validateSignupPassword() {
            this.signupError = "";
            if (!this.signupPassword) {
                this.signupError = "Password is required.";
            } else if (this.signupPassword.length < 6) {
                this.signupError = "Password must be at least 6 characters long.";
            } else if (this.signupPassword !== this.signupConfirmPassword) {
                this.signupError = "Passwords do not match.";
            }
        },
        validateSignupConfirmPassword() {
            this.signupError = "";
            if (this.signupConfirmPassword !== this.signupPassword) {
                this.signupError = "Passwords do not match.";
            }
        },
        validateSignupForm() {
            this.validateFirstName();
            this.validateLastName();
            this.validateSignupNumber();
            this.validateAddress()
            this.validateCity()
            this.validateZipcode()
            this.validateSignupPassword();
            this.validateSignupConfirmPassword();
            return !this.signupError;
        },
        ...mapActions(['login']),
        async handleSubmit() {
            this.validatePhoneNumber();
            this.validatePassword();
            try {
                const response = await axios.post('https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/signin', {
                    phoneNumber: this.phoneNumber,
                    password: this.password
                });

                if (response.status === 200) {
                    localStorage.setItem('authToken', response?.data?.data?.token);
                    await this.login();
                    this.modalTitle = response.data.status
                    this.modalStatus = 'success';
                    this.modalMessage = response.data.message;
                    this.isModalVisible = true;

                    setTimeout(() => {
                        if (this.phoneNumber === '6142181183') {
                            this.$router.push('/admin')
                        } else {
                            this.$router.push('/order-history');
                        }
                    }, 2000);
                } else {
                    this.modalTitle = response.data.status
                    this.modalStatus = 'failure'
                    this.modalMessage = response?.data?.errorDetails?.errorMessage || "Login failed.";
                    this.isModalVisible = true
                    this.$emit('close-modal');
                }
            } catch (error) {
                this.modalTitle = "Failure"
                this.modalStatus = 'failure'
                this.modalMessage = error.response.data.errorDetails.errorMessage || "An error occurred. Please try again.";
                this.isModalVisible = true;
                this.$emit('close-modal');

            }
        },
        async handleSignup() {
            this.validateSignupForm();

            if (this.signupError === '') {
                const address = this.address + ', ' + this.secondary + ', ' + this.city + ', ' + this.state + ', ' + this.zipcode;

                try {
                    const response = await axios.post('https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/signup', {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        phoneNumber: this.signupPhoneNumber,
                        address,
                        password: this.signupPassword
                    });

                    if (response.status === 200) {
                        this.modalTitle = response.data.status || "Success";
                        this.modalStatus = 'success'
                        this.modalMessage = response.data.message;
                        this.isModalVisible = true;
                        localStorage.removeItem("newUserAddress")

                        setTimeout(() => {
                            this.isLogin = true;
                            this.isModalVisible = false;
                        }, 2000);
                    } else {
                        this.modalTitle = response.data.status || "Warning";
                        this.modalStatus = 'warning'
                        this.modalMessage = response?.data?.errorDetails?.errorMessage || "Signup failed.";
                        this.isModalVisible = true;
                        this.$emit('close-modal');
                    }
                } catch (error) {
                    this.modalTitle = "Failure";
                    this.modalStatus = 'failure'
                    this.modalMessage = error.response?.data?.errorDetails?.errorMessage || "An error occurred. Please try again.";
                    this.isModalVisible = true;
                    this.$emit('close-modal');
                }
            }
        },
        toggleForm() {
            this.isLogin = !this.isLogin;
        },
        populateForm(address) {
            this.address = address.houseNo;
            this.street = address.streetName;
            this.city = address.city;
            this.zipcode = address.zipcode;
        }
    },
    mounted() {
        const storedAddress = localStorage.getItem('newUserAddress');
        if (storedAddress) {
            const address = JSON.parse(storedAddress);
            if (address) {
                this.populateForm(address);
            }
        }
    },

};
</script>


<style scoped>
* {
    padding: 0;
    margin: 0;
    font-family: "Noto Sans", sans-serif;
    box-sizing: border-box;
    text-decoration: none;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    50% {
        opacity: 0.5;
        transform: translateY(25px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

#input-group {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.input-container {
    flex: 1;
    min-width: 150px;
}

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15rem;
    height: 100vh;
    background-color: #f4f4f9;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../assets/loginBG.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.page-container #heading {
    font-size: clamp(1.2rem, 2vw, 2rem);
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    max-width: 500px;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    margin: 20px;
}

.mobile-heading {
    display: none;
}

.form-container {
    display: flex;
    flex-direction: column;
}

#heading {
    animation: fadeInUp 0.8s ease-out forwards 0.3s;
    text-align: center;
    font-size: x-large;
    margin-bottom: 20px;
    color: #fff;
}

h6 {
    margin-bottom: 30px;
}

.heading {
    font-size: x-large;
    margin-bottom: 20px;
}

.auth-switch-text {
    margin-bottom: 50px;
    font-size: 1rem;
    color: gray;
}

.auth-method-text {
    color: #0a57a2
}

.phone-input {
    position: relative;
}

.phone-prefix {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    color: #0a57a2;
    pointer-events: none;
    font-size: 16px;
}

.input-group label {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: gray;
    font-size: 16px;
    transition: all 0.3s ease;
    pointer-events: none;
    display: none;
}

.input-group input:focus+label,
.input-group input:not(:placeholder-shown)+label,
.input-group input:valid+label,
.input-group .active {
    display: block;
    top: 6px;
    padding-top: 6px;
    font-size: 0.775rem;
    font-weight: 500;
    color: black;
}

.input-field.phone {
    padding-left: 33px;
}

.input-field {
    width: 100%;
    height: 55px;
    border: none;
    padding: 12px;
    background-color: #eeeeee;
    color: gray;
    outline: none;
    font-size: 16px;
    transition: all 0.5s ease;
    border-radius: 5px;
}

.input-field:focus {
    box-shadow: inset 0 0 0 2px #0a57a2;
    background-color: #ffffff;
}

.input-group {
    position: relative;
    margin-bottom: 20px;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    cursor: pointer;
    color: #AAA8BB;
}

.button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.form-wrapper {
    animation: fadeInUp 0.5s ease-out;
}

.error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

.input-group input.error {
    border: 1px solid red;
}

button {
    width: 100%;
    height: 50px;
    border: none;
    color: #FFFFFF;
    background-color: #0a57a2;
    margin-top: 20px;
    padding: 15px 0;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: 0.3s;
}

button:hover {
    background-color: #31283B;
}

/* Transition effect */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@media (max-width: 768px) {

    button{
        font-size: medium;
        height: 35px;
    }

    .input-field {
        font-size: x-small;
        height: 40px;
    }

    input::placeholder,select{
        font-size: x-small;
        color: gray;
    }

    .phone-prefix {
        font-size: small;
    }

    .input-group input:focus+label,
    .input-group input:not(:placeholder-shown)+label,
    .input-group input:valid+label,
    .input-group .active {
        font-size: 0.5rem;
    }

    .mobile-heading {
        display: block;
        margin-left: 15px;
        margin-bottom: 2rem;
    }

    .mobile-heading p {
        font-weight: 600;
        color: #0a57a2;
    }

    .heading {
        font-size: medium;
        color: gray;
    }

    .page-container {
        flex-direction: column;
        gap: 0;
    }

    .login-wrapper {
        padding: 20px 0;
        margin: 0;
        border-radius: 0;
    }

    .form-container {
        padding: 15px;
    }
}
</style>
