<template>
    <div>
        <div class="modal" v-if="showAddressModal">
            <div class="modal-content">
                <span class="close" @click="confirmClose"><i class="bi bi-x-circle"></i></span>
                <h2>Enter your address to get started.</h2>
                <p>For a hassle-free experience,You can also contact us at <span>+1 (614) 218-1183</span> to schedule your
                    pickup or for any inquiries!</p>
                <form @submit.prevent="submitAddress">
                    <div class="form-group">
                        <label for="address">Address <span class="mandatory">*</span></label>
                        <input type="text" id="address" v-model="form.address" placeholder="Enter your address"
                            required>
                    </div>

                    <p>Make sure to enter in this format <span>9864 Brewster Ln, Unit M</span></p>

                    <div class="form-group">
                        <input type="text" id="secondary-address" v-model="form.secondary"
                            placeholder="Enter your unit or apartment or suite number">
                    </div>

                    <div class="form-group">
                        <label :class="{ active: form.city }" placeholder="City">City</label>
                        <select id="city" class="input-field" v-model="form.city" @change="onCityChange" required>
                            <option value="" disabled>Select a city</option>
                            <option v-for="(zipcodes, city) in cityZipCodeMapping" :key="city" :value="city">
                                {{ city }}
                            </option>
                        </select>
                    </div>

                    <!-- Zip code dropdown for cities other than Columbus -->
                    <div v-if="zipCodes.length >= 1 && form.city !== 'Columbus'" class="form-group">
                        <label for="zipcode">Zip Code:</label>
                        <select id="zipcode" v-model="form.zipcode" required>
                            <option value="" disabled>Select Zip Code</option>
                            <option v-for="zip in zipCodes" :key="zip" :value="zip">{{ zip }}</option>
                        </select>
                    </div>

                    <!-- Zip code input for Columbus -->
                    <div v-if="form.city === 'Columbus'" class="form-group">
                        <label :class="{ active: form.zipcode }" placeholder="zipcode">Zipcode</label>
                        <input type="text" id="zipcode" class="input-field" v-model="form.zipcode"
                            placeholder="Enter Zip Code" @keypress="onlyNumber" />
                    </div>


                    <button type="submit" class="submit-btn">Submit</button>
                </form>
            </div>
        </div>

        <!-- Message modal -->
        <MessageModal :isVisible="isModalVisible" :title="modalTitle" :message="modalMessage" :status="modalStatus"
            :btnTxt="buttonText" @close="handleModalClose" @go="redirectTo" />
    </div>
</template>


<script>
import axios from 'axios';
import MessageModal from './messageModal.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        MessageModal
    },
    data() {
        return {
            form: {
                address: '',
                secondary: '',
                city: '',
                state: 'OH',
                zipcode: '',
            },
            cityZipCodeMapping: {
                Westerville: ['43081', '43082', '43086'],
                Worthington: ['43085', '43229'],
                Powell: ['43065'],
                Dublin: ['43002', '43016', '43017'],
                Delaware: ['43015'],
                'Lewis Center': ['43015', '43035'],
                Columbus: ''
            },
            zipCodes: [],
            showAddressModal: true,
            isModalVisible: false,
            modalTitle: '',
            modalMessage: '',
            modalStatus: '',
            buttonText: ''
        };
    },
    computed: {
        ...mapGetters({
            userAddress: 'userAddress',
            // orders: 'orders'    
        })
    },
    watch: {
        userAddress: {
            immediate: true, // Run it on component mount
            handler(newAddress) {
                if (newAddress) {
                    this.populateForm(newAddress);
                }
            }
        }
    },
    methods: {
        onlyNumber(event) {
            const char = String.fromCharCode(event.which);
            if (!/[0-9]/.test(char)) {
                event.preventDefault();
            }
        },
        populateForm(address) {
            const parts = address.split(', ').map(item => item.trim());


            // Assigning each part based on the array's length.
            this.form.address = parts[0] || ''; // House number or primary address

            // Check if secondary part exists (it's between the address and city)
            if (parts.length === 5) {
                this.onCityChange();
                this.form.secondary = parts[1];
                this.form.city = parts[2];
                if (this.form.city) {
                    this.onCityChange();
                }
                this.form.zipcode = parts[4];
            } else {
                this.form.secondary = '';
                this.form.city = parts[1];
                if (this.form.city) {
                    this.onCityChange();
                }
                const [, zip] = parts[2].split(' ');
                this.form.zipcode = zip
            }

        },
        onCityChange() {
            const selectedCity = this.form.city;

            if (selectedCity === 'Columbus') {
                this.zipCodes = []; // Allow manual entry for Columbus
                this.form.zipcode = ''; // Clear any existing zipcode
            } else {
                this.zipCodes = this.cityZipCodeMapping[selectedCity] || [];
                // Auto-fill if there's only one zip code
                if (this.zipCodes.length === 1) {
                    this.form.zipcode = this.zipCodes[0];
                } else {
                    this.form.zipcode = ''; // Reset if multiple zip codes
                }
            }
        },
        submitAddress() {

            // console.log(this.form.houseNo, this.form.streetName, this.form.city, this.form.zipcode);

            // Validate required fields
            if (!this.form.address || !this.form.city || !this.form.state || !this.form.zipcode) {
                this.modalTitle = "Input Error";
                this.modalStatus = 'failure';
                this.modalMessage = "Please enter a valid address.";
                this.isModalVisible = true;
                return;
            }

            this.$store.dispatch('updateAddress', {
                address: this.form.address,
                secondary: this.form.secondary,
                city: this.form.city,
                state: this.form.state,
                zipcode: this.form.zipcode,
            });

            const addressParts = [this.form.address, this.form.secondary, this.form.city, this.form.state, this.form.zipcode]
                .filter(part => part && part.trim())
                .map(part => part.trim())
                .join('+');

            // console.log(addressParts);

            axios.get(`https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/checkAddressAvailability?address=${addressParts}`)
                .then(response => {
                    // console.log('Response received:', response.data);

                    if (response.data && response.data.status === "success") {
                        // Handle service availability
                        const serviceStatus = response.data?.data?.serviceStatus;
                        const message = response.data?.message || "Service is not available for this address.";

                        if (serviceStatus) {
                            this.modalTitle = "Success";
                            this.modalStatus = 'success';
                            this.modalMessage = "Service is available for this address!";
                            this.showAddressModal = false;
                            this.buttonText = 'OK'
                            // console.log(this.form);
                            setTimeout(() => {
                                if (this.$store.getters.isLoggedIn) {
                                    this.$emit('close-modal');
                                } else {
                                    localStorage.setItem('newUserAddress', JSON.stringify(this.form))
                                    this.$router.push('/login');
                                }
                            }, 3000);
                        } else {
                            this.modalTitle = "Warning";
                            this.modalStatus = 'warning';
                            this.modalMessage = message;
                        }
                        this.isModalVisible = true;
                    } else {
                        // Handle unsuccessful response
                        this.modalTitle = response?.data?.status || "Error";
                        this.modalStatus = 'failure';
                        this.modalMessage = response?.data?.data?.message || "An unexpected error occurred.";
                        this.isModalVisible = true;
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    // Handle errors in request
                    this.modalTitle = error?.response?.data?.status || "Error";
                    this.modalStatus = 'failure';
                    this.modalMessage = error?.response?.data?.data?.message || "Unable to check address availability. Please try again.";
                    this.isModalVisible = true;
                    // this.buttonText = ''
                    // setTimeout(() => {
                    //     if (this.isModalVisible) {
                    //         this.isModalVisible = false;
                    //         this.$router.push('/');
                    //     }
                    // }, 1500);
                });
        },
        confirmClose() {
            this.modalTitle = "Cancel Confirmation";
            this.modalStatus = 'warning';
            this.modalMessage = "Are you sure you want to cancel? You will be redirected to the homepage.";
            this.isModalVisible = true;
            this.buttonText = 'Yes'
        },

        // Method to handle modal close
        handleModalClose() {
            this.isModalVisible = false;
            // if (shouldRedirect) {
            //     this.$router.push('/');
            // }
        },
        redirectTo() {
            this.isModalVisible = false;
            this.redirectBasedOnMessage()
        },
        redirectBasedOnMessage() {
            if (this.modalTitle === "Cancel Confirmation" || this.modalStatus === 'failure') {
                this.$router.push('/');
            } else {
                this.$emit('close-modal');
            }
            // Add more conditions as needed
        },

    },
    // mounted() {
    //     // Redirect to login if userAddress is not present
    //     if (!this.userAddress) {
    //         this.$router.push('/login');
    //     }
    // },
};
</script>

<style scoped>
.mandatory {
    font-size: small;
    color: rgb(245, 64, 64);
}

.modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
    /* Darker background for better contrast */
}

.modal-content {
    background-color: #fff;
    margin: 10% auto;
    padding: 30px 20px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    /* Stronger shadow */
    width: 90%;
    max-width: 500px;
    /* Increase max-width for more space */
    position: relative;
    z-index: 1001;
    animation: fadeIn 0.3s ease-in-out;
    /* Add fade-in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #666;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.close:hover,
.close:focus {
    color: #e74c3c;
    cursor: pointer;
}

h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

h2+p,
.modal-content p {
    padding-left: 10px;
    font-size: 0.9rem;
    font-style: italic;
    color: gray;
}

h2+p span,
.modal-content p span {
    color: #0a57a2;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    color: #555;
}

.form-group select,
.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.form-group select:hover,
.form-group input:hover {
    border-color: #666;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #0a57a2;
    /* Blue border on focus */
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.submit-btn {
    background-color: #0a57a2;
    color: #fff;
    padding: 12px 0;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    margin-top: 15px;
}

.submit-btn:hover {
    background-color: #073D7A;
}

.submit-btn:active {
    background-color: #004494;
}

@media (max-width: 768px) {
    .modal-content {
        margin: 20% auto;
        padding: 20px;
        max-width: 80%;
        top: 0;
        border-radius: 0;
    }

    h2 {
        font-size: 1.5rem;
    }
}

/* Additional customizations */
.form-group select,
.form-group input {
    transition: background-color 0.3s ease;
}

.form-group input:disabled,
.form-group select:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
}
</style>
