<template>
    <apexchart type="treemap" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
    components: {
        apexchart: VueApexCharts,
    },
    setup() {
        const series = ref([]); // Ref to hold the series data
        const chartOptions = {
            legend: {
                show: false,
            },
            chart: {
                height: 350,
                type: 'treemap',
            },
            title: {
                text: 'Orders from cities',
            },
        };

        // Function to fetch orders and extract city data
        const fetchCityData = async () => {
            const token = localStorage.getItem('authToken'); // Get the token from local storage

            try {
                const response = await fetch("https://laundry-service-usa-a14349c1eb96.herokuapp.com/maxcleaners/fetchOrders?size=200", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                const data = await response.json(); // Parse the response as JSON
                const orders = data.data.orders; // Access orders array

                // Create a map to count occurrences of each city
                const cityCounts = {};
                orders.forEach(order => {
                    const city = order.city; // Get the city from each order
                    cityCounts[city] = (cityCounts[city] || 0) + 1; // Increment the count for the city
                });

                // Transform the cityCounts into the format required by ApexCharts
                series.value = [
                    {
                        data: Object.entries(cityCounts).map(([city, count]) => ({
                            x: city,
                            y: count,
                        })),
                    },
                ];
            } catch (error) {
                console.error('Error fetching city data:', error); // Log any errors
            }
        };

        // Fetch city data when the component is mounted
        onMounted(() => {
            fetchCityData();
        });

        return {
            series,
            chartOptions,
        };
    },
});
</script>

<style scoped></style>